import { useContext, useState } from "react";
import styles from "./ordersPage.module.scss";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Page from "../../../components/layout/page/Page";
import Alert from "../../../components/layout/Alert/Alert";
import OrderConfirm from "../components/OrderConfirm";
import { UserContext } from "../../../store/AppContextProvider";
import { FaCircleCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { logDOM } from "@testing-library/react";

const OrdersPage = (props) => {
  const { user, setUser } = useContext(UserContext);
  const [orderConfirm, setOrderConfirm] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });

  const [order, setOrder] = useState({
    userEmail: String,
    userName: String,
    userSurname: String,
    address: String,
    city: String,
    zipcode: String,
    taxNumber: String,
    isVerified: Boolean,
    sessionId: String,
    type: String,
    galleriesQuantity: Number,
    price: Number,
    amount: Number, // kwota podana w groszach
    currency: String,
    description: String,
    orderId: Number,
  });

  const PRICING = [
    {
      id: 1,
      type: "Pojedyncza",
      description: "Pojedyncza galeria",
      price: 9,
      amount: 900,
      galleriesQuantity: 1,
      functionalities: ["1 galeria", "wszystkie dostępne narzędzia", "brak limitu zdjęć", "support 24/7"],
    },
    {
      id: 2,
      type: "Pakiet 10",
      description: "Pakiet 10 galerii z 20% zniżką",
      price: 72,
      discount: "-20%",
      amount: 7200,
      galleriesQuantity: 10,
      functionalities: ["10 galerii", "wszystkie dostępne narzędzia", "brak limitu zdjęć", "support 24/7"],
    },
    {
      id: 3,
      type: "Pakiet 30",
      description: "Pakiet 30 galerii z 30% zniżką",
      price: 189,
      discount: "-30%",
      amount: 18900,
      galleriesQuantity: 30,
      functionalities: ["30 galerii", "wszystkie dostępne narzędzia", "brak limitu zdjęć", "support 24/7"],
    },
  ];

  const closeOrderConfirm = () => {
    setOrderConfirm(false);
    setOrder({
      type: String,
      galleriesQuantity: Number,
      orderId: String,
      name: String,
      email: String,
      price: Number,
    });
  };

  // Funkcja sprawdzająca, czy wszystkie pola oprócz taxnumber są wypełnione
  function areAllFieldsFilled(obj) {
    for (const key in obj) {
      if (key !== "taxNumber" && (obj[key] === null || obj[key] === undefined || obj[key] === "")) {
        return false;
      }
    }
    return true;
  }

  const handleOrder = (pricing) => {
    const newOrder = {
      userEmail: user.email,
      userName: user.name,
      userSurname: user.surname,
      address: user.address,
      city: user.city,
      zipcode: user.zipcode,
      taxNumber: user.taxNumber,
      sessionId: uuidv4(),
      type: pricing.type,
      galleriesQuantity: pricing.galleriesQuantity,
      price: pricing.price,
      amount: pricing.amount, // kwota podana w groszach
      description: pricing.description,
    };

    // console.log(newOrder);

    // Warunek wykonujący akcję tylko jeśli wszystkie pola są wypełnione
    if (areAllFieldsFilled(newOrder)) {
      setOrder(newOrder);
      setOrderConfirm(true);
    } else {
      setAlert({
        message: `Brakuje wymaganych danych. Uzupełnij swój profil.`,
        type: "info",
      });
    }
  };

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.pricingCards}>
          {PRICING.map((pricing) => (
            <div className={styles.pricingCard} key={pricing.id}>
              {pricing.discount && (
                <div className={styles.discount}>
                  <p className={styles.discountAmount}>{pricing.discount}</p>
                </div>
              )}
              <h3>{pricing.type}</h3>
              <h4>
                {pricing.price} zł <span className={styles.brutto}>brutto</span>
              </h4>
              <div className={styles.functionalities}>
                {pricing.functionalities.map((functionality) => (
                  <div key={functionality} className={styles.functionality}>
                    <div className={styles.icon}>
                      <FaCircleCheck size="20px" color="#0096EA" />
                    </div>
                    <p>{functionality}</p>
                  </div>
                ))}
              </div>
              <button
                className={styles.callToAction}
                onClick={() => {
                  handleOrder(pricing);
                }}
              >
                Wybieram
              </button>
            </div>
          ))}
        </div>
      </div>
      {orderConfirm && <OrderConfirm order={order} close={closeOrderConfirm} />}
      {alert.message ? (
        <Alert
          alert={alert}
          close={() =>
            setAlert({
              message: "",
              type: "",
            })
          }
        />
      ) : (
        ""
      )}
    </Page>
  );
};

export default OrdersPage;
