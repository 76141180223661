import styles from "./customersList.module.scss";
import { useState } from "react";
import CustomerItem from "../customer-item/CustomerItem";

const CustomersList = ({ customers }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [customersPerPage, setCustomersPerPage] = useState(10);

  const pagesVisited = pageNumber * customersPerPage;
  const pagesQuantity = Math.ceil(customers.length / customersPerPage);
  const pagesNumbers = [...Array(pagesQuantity + 1).keys()].slice(1);

  const previousPage = () => {
    if (pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber != pagesQuantity - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  const changePage = (number) => {
    setPageNumber(number - 1);
  };

  const handleCustomersPerPageChange = (e) => {
    setCustomersPerPage(Number(e.target.value));
    setPageNumber(0); // Resetuje do pierwszej strony po zmianie ilości
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.galleriesList}>
          {customers.slice(pagesVisited, pagesVisited + customersPerPage).map((customer) => {
            return <CustomerItem key={customer._id} customer={customer} />;
          })}
        </div>

        <div className={styles.controls}>
          <div style={{ width: "195px" }}></div>
          {customers.length > customersPerPage && (
            <div className={styles.pagination}>
              <span onClick={previousPage}>Poprzednia strona</span>
              <div className={styles.numbersContainer}>
                {pagesNumbers.map((number, index) => (
                  <span
                    onClick={() => changePage(number)}
                    key={index}
                    className={pageNumber + 1 === number ? styles.active : ""}
                  >
                    {number}
                  </span>
                ))}
              </div>
              <span onClick={nextPage}>Następna strona</span>
            </div>
          )}
          <div className={styles.selectContainer}>
            <label htmlFor="customersPerPage">Ilość klientów na stronę: </label>
            <select id="customersPerPage" value={customersPerPage} onChange={handleCustomersPerPageChange}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
        </div>

        {/* {customers.length > customersPerPage && (
          <div className={styles.pagination}>
            <span onClick={previousPage}>Poprzednia strona</span>
            <div className={styles.numbersContainer}>
              {pagesNumbers.map((number, index) => (
                <span
                  onClick={() => changePage(number)}
                  key={index}
                  className={pageNumber + 1 === number ? styles.active : ""}
                >
                  {number}
                </span>
              ))}
            </div>
            <span onClick={nextPage}>Następna strona</span>
          </div>
        )} */}
      </div>
    </>
  );
};

export default CustomersList;
