import { useState } from "react";
import styles from "./galleryList.module.scss";
import GalleryItem from "../galleryItem/GalleryItem";

const GalleriesList = ({ galleries }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [galleriesPerPage, setGalleriesPerPage] = useState(10);

  const pagesVisited = pageNumber * galleriesPerPage;
  const pagesQuantity = Math.ceil(galleries.length / galleriesPerPage);
  const pagesNumbers = [...Array(pagesQuantity + 1).keys()].slice(1);

  const previousPage = () => {
    if (pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber != pagesQuantity - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  const changePage = (number) => {
    setPageNumber(number - 1);
  };

  const handleGalleriesPerPageChange = (e) => {
    setGalleriesPerPage(Number(e.target.value));
    setPageNumber(0); // Resetuje do pierwszej strony po zmianie ilości
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.galleriesList}>
          {galleries.slice(pagesVisited, pagesVisited + galleriesPerPage).map((gallery) => {
            return <GalleryItem key={gallery._id} gallery={gallery} />;
          })}
        </div>

        <div className={styles.controls}>
          <div style={{ width: "195px" }}></div>
          {galleries.length > galleriesPerPage && (
            <div className={styles.pagination}>
              <span onClick={previousPage}>Poprzednia strona</span>
              <div className={styles.numbersContainer}>
                {pagesNumbers.map((number, index) => (
                  <span
                    onClick={() => changePage(number)}
                    key={index}
                    className={pageNumber + 1 === number ? styles.active : ""}
                  >
                    {number}
                  </span>
                ))}
              </div>
              <span onClick={nextPage}>Następna strona</span>
            </div>
          )}
          <div className={styles.selectContainer}>
            <label htmlFor="galleriesPerPage">Ilość galerii na stronę: </label>
            <select id="galleriesPerPage" value={galleriesPerPage} onChange={handleGalleriesPerPageChange}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleriesList;
