import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import styles from "./customerGalleries.module.scss";
import Page from "../../../components/layout/page/Page";
import GalleriesList from "../../../components/galleries/galleryList/GalleryList";
import LoadingBar from "../../../components/layout/LoadingBar";
import ReturnButton from "../../../components/layout/returnButton/ReturnButton";

const CustomerGalleriesPage = () => {
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(false);

  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    getCustomerGalleries();
  }, []);

  const getCustomerGalleries = async () => {
    setLoading(true);
    await axios.get(`/customers/galleries/${params.id}`).then((res) => {
      setGalleries(res.data);
    });
    setLoading(false);
  };
  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.returnButton}>
          <ReturnButton title="Powrót do profilu klienta" url={`/customers/${params.id}`} />
        </div>
        <div className={styles.titleHeader}>
          <div>
            <h3>Lista galerii klienta</h3>
          </div>
        </div>
        {galleries.length ? (
          <GalleriesList galleries={galleries} />
        ) : (
          <div className={styles.emptyList}>
            <p>Klient nie posiada galerii</p>
          </div>
        )}
      </div>
    </Page>
  );
};

export default CustomerGalleriesPage;
